<template>
  <div>
    <!--begin::Card-->
    <div class="card card-custom card-transparent">
      <OverlayLoader :is-submitting="loader.show" :message="loader.message" :timing="loader.timing">
        <div class="card-body p-0">
          <div class="alert alert-success mb-5 p-5" role="alert">
            <h4 class="alert-heading">Informações importantes!</h4>
            <p>
              Para realizar a emissão de SnailCoin e iniciar um contrato conosco é necessario realizarmos uma breve
              proposta.
            </p>
            <div class="border-bottom border-white opacity-20 mb-5" />
            <p class="mb-0">
              Após a finalização desse formulário, sua proposta será enviada para nossa equipe, para que a mesma seja
              analizada.
            </p>
          </div>
          <!--begin: Wizard-->
          <div id="kt_wizard_v3" class="wizard wizard-3" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Wizard Nav-->
            <div class="wizard-nav" style="background-color: #e4e6ef">
              <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>1.</span>
                      Sobre você
                    </h3>
                    <div class="wizard-bar" />
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>2.</span>
                      Simulação
                    </h3>
                    <div class="wizard-bar" />
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body">
                <div class="row justify-content-center px-8 px-lg-10">
                  <div class="col-xl-12 col-xxl-12 p-0">
                    <!--begin: Wizard Form-->
                    <b-form class="form mt-0 mt-lg-10">
                      <div class="row">
                        <div class="col-lg-12">
                          <!--begin: Wizard Step 1-->
                          <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                            <div class="mb-10 text-dark">
                              <h5>Nós forneca alguns dados sobre você</h5>
                            </div>
                            <b-form-group label="Qual é a sua renda mensal ?">
                              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                                <money
                                  v-model="$v.form.monthly_income.$model"
                                  v-bind="maskMoneyReal()"
                                  :class="!$v.form.monthly_income.$model ? 'is-invalid' : 'is-valid'"
                                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                                  :state="validateState('monthly_income')"
                                />
                                <b-form-invalid-feedback v-if="!$v.form.monthly_income.required">
                                  Insira um valor
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.form.monthly_income.minValue">
                                  Insira um valor.
                                </b-form-invalid-feedback>
                              </b-input-group>
                            </b-form-group>

                            <b-form-group label="Qual é o seu tipo de atividade profissional ?" label-for="address">
                              <div class="custom-radio-square">
                                <div
                                  v-for="option in form_parameters.activities_options"
                                  :key="option.id"
                                  class="radio-tile-group"
                                >
                                  <div class="input-container">
                                    <input
                                      id="walk"
                                      v-model="$v.form.professional_activity.$model"
                                      class="radio-button"
                                      type="radio"
                                      name="professional_activity"
                                      :value="option.id"
                                      :state="validateState('professional_activity')"
                                    />
                                    <div class="radio-tile">
                                      <label for="walk" class="radio-tile-label">
                                        {{ option.name }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <small
                                v-if="$v.form.professional_activity.$invalid && $v.form.professional_activity.$dirty"
                                class="text-danger"
                              >
                                Selecione sua atividade
                              </small>
                            </b-form-group>

                            <b-form-group label="Como a emissão de SnailCoin vai ajudar você ?" label-for="address">
                              <div class="custom-radio-square">
                                <div
                                  v-for="option in form_parameters.purposes_options"
                                  :key="option.id"
                                  class="radio-tile-group"
                                >
                                  <div class="input-container">
                                    <input
                                      id="walk"
                                      v-model="$v.form.purpose_resource.$model"
                                      class="radio-button"
                                      type="radio"
                                      name="purpose_resource"
                                      :value="option.id"
                                      :state="validateState('purpose_resource')"
                                    />
                                    <div class="radio-tile">
                                      <label for="walk" class="radio-tile-label">
                                        {{ option.name }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <b-form-invalid-feedback :style="!$v.form.purpose_resource.$model ? 'display:block' : ''">
                                O campo bairro é obrigatório.
                              </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group label="Qual é o valor estimado do seu imóvel ?">
                              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                                <money
                                  v-model="$v.form.asset_estimated_value.$model"
                                  v-bind="maskMoneyReal()"
                                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                                  :class="!$v.form.asset_estimated_value.$model ? 'is-invalid' : 'is-valid'"
                                  :state="validateState('asset_estimated_value')"
                                />
                                <b-form-invalid-feedback>Insira um valor.</b-form-invalid-feedback>
                              </b-input-group>
                            </b-form-group>
                          </div>
                          <!--end: Wizard Step 1-->

                          <!--begin: Wizard Step 2-->
                          <div class="pb-5" data-wizard-type="step-content">
                            <div class="row d-flex align-items-center">
                              <div class="col-lg-5">
                                <div
                                  class="d-flex flex-row-auto bgi-no-repeat bgi-position-y-center bgi-size-contain pt-2 pt-lg-5 h-350px"
                                  style="background-image: url(/media/svg/illustrations/features.svg)"
                                />
                              </div>
                              <div class="col-lg-7">
                                <div class="pb-10 pb-lg-15">
                                  <h3 class="font-weight-bolder text-dark font-size-h2">Simulação</h3>
                                  <div class="text-muted font-weight-bold font-size-h5">
                                    Realizamos um simulação com base nos dados mencionados!
                                  </div>
                                </div>

                                <b-card bg-variant="light">
                                  <table class="table rounded">
                                    <tbody>
                                      <tr>
                                        <td class="text-align-left">
                                          <b>Valor do imóvel</b>
                                        </td>
                                        <td style="text-align: left">
                                          <span class="cifrao">R$</span>
                                          100.000,00
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left">
                                          <b>Valor em SnailCoin emitidos</b>
                                        </td>
                                        <td style="text-align: left">
                                          <span class="cifrao">SN$</span>
                                          60.000,00
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left">
                                          <b>Prazo</b>
                                        </td>
                                        <td style="text-align: left">12 meses (até 30 anos)</td>
                                      </tr>
                                      <tr>
                                        <td align="left">
                                          <b>Taxa de locação</b>
                                        </td>
                                        <td style="text-align: left">3% ao ano</td>
                                      </tr>
                                      <tr>
                                        <td align="left">
                                          <b>Taxa de administração</b>
                                          <span id="tooltip-target-1" class="badge badge-primary badge-xs">
                                            <i class="fa-1x fa fa-exclamation text-white" />
                                          </span>
                                          <b-tooltip target="tooltip-target-1" triggers="hover">
                                            I am tooltip
                                            <b>component</b>
                                            content!
                                          </b-tooltip>
                                        </td>
                                        <td style="text-align: left">
                                          <span class="cifrao">R$</span>
                                          1.800,00 (3%)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left">
                                          <b>Taxa de avaliação do imóvel</b>
                                        </td>
                                        <td style="text-align: left">
                                          <span class="cifrao">R$</span>
                                          11.672,07
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </b-card>
                              </div>
                            </div>
                          </div>
                          <!--end: Wizard Step 2-->
                        </div>
                      </div>

                      <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10">
                        <div class="mr-2">
                          <button
                            class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-prev"
                          >
                            Voltar
                          </button>
                        </div>
                        <div>
                          <button
                            ref="btn_submit"
                            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-submit"
                            @click="onSubmit"
                          >
                            Enviar Proposta
                          </button>
                          <button
                            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-next"
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </b-form>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Bpdy-->
          </div>
          <!--end: Wizard-->
        </div>
      </OverlayLoader>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import KTWizard from '@/assets/js/components/wizard';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import states from '@/core/utils/json/states.json';
import cities from '@/core/utils/json/cities.json';
import { validationMessage } from 'vuelidate-messages';
import formMessages from '@/core/utils/validationMessages';
import { mapGetters } from 'vuex';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import { Money } from 'v-money';

export default {
  name: 'NewProposal',
  components: {
    Money,
    OverlayLoader
  },
  mixins: [validationMixin],
  data() {
    return {
      loader: {
        show: false,
        message: '',
        timing: false
      },
      gettingCep: {
        show: false,
        status: '',
        message: ''
      },
      form: {
        monthly_income: 0,
        professional_activity: null,
        purpose_resource: null,
        asset_estimated_value: 0
      },
      optionsState: states,
      optionsCities: []
    };
  },
  computed: {
    ...mapGetters(['currentStatus', 'form_parameters'])
  },
  watch: {
    'form.step_2.asset_state_id': function (state) {
      this.optionsCities = cities[state].cities;
    }
  },
  validations: {
    form: {
      monthly_income: {
        required,
        minValue: minValue(1)
      },
      professional_activity: {
        required
      },
      purpose_resource: {
        required
      },
      asset_estimated_value: {
        required
      }
    }
  },
  mounted() {
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v3', {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    const vuevalidate = this.$v;
    wizard.on('change', function (wizardObj) {
      if (wizardObj.getStep() > wizardObj.getNewStep()) {
        return; // Skip if stepped back
      }

      // Validate form before change wizard step
      const validator = vuevalidate.form; // get validator for current step

      validator.$touch();

      if (validator.$anyError) {
        wizardObj.stop();
        return;
      }
    });
  },
  methods: {
    async onSubmit() {
      this.loader.show = true;
      this.loader.timing = true;
      this.loader.message = 'Aguarde... Enquanto estamos formalizamos sua prosposta.';

      const form = {
        monthly_income: this.$v.form.monthly_income.$model,
        professional_activity_id: this.$v.form.professional_activity.$model,
        purpose_resource_id: this.$v.form.purpose_resource.$model,
        asset_estimated_value: this.$v.form.asset_estimated_value.$model
      };

      const submitButton = this.$refs['btn_submit'];

      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('STORE_PROPOSAL', form)
        .then((response) => {
          this.$swal({
            title: response.success,
            icon: 'success'
          });

          this.$bvModal.hide('modal-new-proposal');
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
          this.$store.dispatch('GET_PROPOSAL_LIST');
        })
        .catch(() => {
          this.loader.show = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validationMsg: validationMessage(formMessages)
  }
};
</script>
